import LearningLockerLogin from '../services/learningLockerLogin'

const useSetLRSCookie = (user, setCookie, addError, translate) => {
	const setLRSCookieAsync = async () => {
		try {
			if (user.email !== '' && user.role.services.LRS) {
				const LRSlogin = await LearningLockerLogin(
					user.email,
					user.deploymentGroup.api
				)

				if (LRSlogin.status && LRSlogin.status !== 200) {
					addError(`${translate(LRSlogin.code)}`)
				} else {
					setCookie(
						`access_token_user_${LRSlogin.data.userId}`,
						LRSlogin.data.token,
						{
							path: '/',
							domain: 'ludusservers.com',
						}
					)
				}
			}
		} catch (error) {
			console.error(error)
		}
	}

	setLRSCookieAsync()
}

export default useSetLRSCookie
